import { AuthService } from './../../auth/auth.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  AngularFirestore,
  DocumentChangeAction,
} from '@angular/fire/firestore';
import { Strength } from './models/strength.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StrengthsService {
  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService
  ) {}

  addNewStrength(strength: Strength): Promise<any> {
    console.log(`strength: ${strength.name}`);
    console.log(strength);

    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection('strengths')
        .add(Object.assign({}, strength))
        .then(
          (res) => {},
          (err) => reject(err)
        );
    });
  }

  updateStrength(strength: Strength): Promise<void> {
    return this.firestore
      .collection('strengths')
      .doc(strength.id)
      .set(strength);
  }

  getStrength(id: string) {
    return this.firestore.collection('strengths').doc(id).get();
  }

  getStrengths(): Observable<DocumentChangeAction<unknown>[]> {
    return this.firestore
      .collection('strengths')
      .snapshotChanges();
  }

  deleteStrength(strength: Strength): Promise<void> {
    return this.firestore.collection('strengths').doc(strength.id).delete();
  }
}
