import { UserPortalNavigationModule } from './user-portal-navigation/user-portal-navigation.module';
import { IonicModule } from '@ionic/angular';
import { PersonalStrengthsModule } from './personal-strengths/personal-strengths.module';
import { StrengthsModule } from './strengths/strengths.module';
import { SkillsModule } from './skills/skills.module';
import { AuthModule } from './../auth/auth.module';
import { UserPortalRoutingModule } from './user-portal-routing.module';
import { ExperiencesModule } from './experiences/experiences.module';
import { UserPortalComponent } from './user-portal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [UserPortalComponent],
  imports: [
    CommonModule,
    IonicModule,
    AuthModule,
    UserPortalNavigationModule,
    ExperiencesModule,
    SkillsModule,
    UserPortalRoutingModule,
    StrengthsModule,
    PersonalStrengthsModule,
  ],
  exports: [],
  providers: [],
})
export class UserPortalModule {}
