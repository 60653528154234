import { SkillsService } from './../../skills.service';
import { DocumentChangeAction } from '@angular/fire/firestore';
import { Skill } from './../../models/skill.interface';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-skill-detail',
  templateUrl: './skill-detail.component.html',
  styleUrls: ['./skill-detail.component.scss'],
})
export class SkillDetailComponent implements OnInit {
  @Input()
  skill: Skill;
  editing = false;

  newString: string;

  @Output()
  edit: EventEmitter<any> = new EventEmitter();
  @Output()
  remove: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private skillsService: SkillsService
  ) {}
  ngOnInit(): void {
    this.route.params.forEach((params: Skill) => {
      if (params.id !== undefined) {
        this.skillsService.getSkill(params.id).subscribe((hero) => {

          this.skill = hero.data() as Skill;
          console.log(`The ID is: '${params.id}'`);
          console.log(hero);
        });
      }
    });
    // switchMap((data: Skill) => this.skillsService.getSkill(data.id))
    // .subscribe((data: Skill) => this.skill = data));
  }

  toggleEdit(): void {
    if (this.editing) {
      console.log(
        `Changed name from ${this.skill.name} to ${this.newString}`
      );
      this.skill.name = this.newString;
      this.edit.emit(this.skill);
    } else {
      this.newString = this.skill.name;
    }
    this.editing = !this.editing;
  }
  cancelEdit(): void {
    this.editing = false;
    this.newString = this.skill.name;
  }

  deleteEntry(event: Event): void {
    console.log('REMOVE');
    this.remove.emit(this.skill);
  }

  onNameChanged(val: string) {
    console.log(val);
    this.newString = val;
  }
}
