import { ExperiencesService } from './../../experiences.service';
import { DocumentChangeAction } from '@angular/fire/firestore';
import { Experience } from './../../models/experiences';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-experience-detail',
  templateUrl: './experience-detail.component.html',
  styleUrls: ['./experience-detail.component.scss'],
})
export class ExperienceDetailComponent implements OnInit {
  @Input()
  experience: Experience;
  editing = false;

  newString: string;

  @Input()
  compact = false;

  @Output()
  edit: EventEmitter<any> = new EventEmitter();
  @Output()
  remove: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private experiencesService: ExperiencesService
  ) {}
  ngOnInit(): void {
    this.route.params.forEach((params: Experience) => {
      if (params.id !== undefined) {
        this.experiencesService.getExperience(params.id).subscribe((hero) => {
          this.experience = hero.data() as Experience;
          console.log(`The ID is: '${params.id}'`);
          console.log(hero);
        });
      }
    });
    // switchMap((data: Experience) => this.experiencesService.getExperience(data.id))
    // .subscribe((data: Experience) => this.experience = data));
  }

  toggleEdit(): void {
    if (this.editing) {
      console.log(
        `Changed name from ${this.experience.name} to ${this.newString}`
      );
      this.experience.name = this.newString;
      this.edit.emit(this.experience);
    } else {
      this.newString = this.experience.name;
    }
    this.editing = !this.editing;
  }
  cancelEdit(): void {
    this.editing = false;
    this.newString = this.experience.name;
  }

  deleteEntry(event: Event): void {
    console.log('REMOVE');
    this.remove.emit(this.experience);
  }

  onNameChanged(val: string) {
    console.log(val);
    this.newString = val;
  }
}
