export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyAV4zDk3sc_uSd4T3e00hHDAnPb5sYjH3g',
    authDomain: 'staerkensache.firebaseapp.com',
    databaseURL: 'https://staerkensache.firebaseio.com',
    projectId: 'staerkensache',
    storageBucket: 'staerkensache.appspot.com',
    messagingSenderId: '800763503510',
    appId: '1:800763503510:web:873953f77d5eba238fb3b7',
    measurementId: 'G-G69E7DPQZ8'
  }
};
