import { UserPortalNavigationModule } from './user-portal/user-portal-navigation/user-portal-navigation.module';
import { ComponentsModule } from './components/components.module';
import { UnauthenticatedHeaderComponent } from './components/unauthenticated-header/unauthenticated-header.component';
import { LoaderComponent } from './components/Loader/Loader.component';
import { LoadingCircleComponent } from './components/loading-circle/loading-circle.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { HomeComponent } from './components/home/home.component';
import { CommonModule } from '@angular/common';
import { UserPortalModule } from './user-portal/user-portal.module';
import { AuthModule } from './auth/auth.module';
import { environment } from './../environments/environment.prod';
import { GreatExperiencesService } from './user-portal/experiences/great-experiences.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthService } from './auth/auth.service';
import { RouterModule, RouteReuseStrategy } from '@angular/router';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { LoadingBarsComponent } from './components/loading-bars/loading-bars.component';

@NgModule({
  declarations: [
    AppComponent,

   ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    UserPortalModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    IonicModule.forRoot(),

    AuthModule,
    UserPortalNavigationModule,
    AppRoutingModule,
    ComponentsModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    GreatExperiencesService,
    AuthService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
