import { AuthService } from './../../auth/auth.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  Action,
  AngularFirestore,
  DocumentChangeAction,
  DocumentSnapshot,
} from '@angular/fire/firestore';
import { Experience } from './models/experiences';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExperiencesService {
  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService
  ) {}

  addNewExperience(experience: Experience): Promise<any> {
    console.log(`exp: ${experience.name}`);

    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection('experiences')
        .add(Object.assign({}, experience))
        .then(
          (res) => {},
          (err) => reject(err)
        );
    });
  }

  updateExperience(expierience: Experience): Promise<void> {
    return this.firestore
      .collection('experiences')
      .doc(expierience.id)
      .set(expierience);
  }

  getExperience(id: string) {
    return this.firestore.collection('experiences').doc(id).get();
  }

  getExperiences(): Observable<DocumentChangeAction<unknown>[]> {
    return this.firestore
      .collection('experiences', (e) => e.where('ownerID', '==', `${this.authService.user.uid}`))
      .snapshotChanges();
  }

  deleteExperience(experience: Experience): Promise<void> {
    return this.firestore.collection('experiences').doc(experience.id).delete();
  }
}
