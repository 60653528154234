import { NotFoundComponent } from "./not-found/not-found.component";
import { NavigationComponent } from "./navigation/navigation.component";
import { LoadingCircleComponent } from "./loading-circle/loading-circle.component";
import { LoadingBarsComponent } from "./loading-bars/loading-bars.component";
import { HomeComponent } from "./home/home.component";
import { UnauthenticatedHeaderComponent } from "./unauthenticated-header/unauthenticated-header.component";
import { AuthGuard } from "./../auth/auth.guard";
import { IonicModule } from "@ionic/angular";
import { FormsModule } from "@angular/forms";
import { AuthRoutingModule } from "./../auth/auth-routing.module";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

@NgModule({
  declarations: [
    UnauthenticatedHeaderComponent,
    HomeComponent,
    LoadingBarsComponent,
    LoadingCircleComponent,
    NavigationComponent,
    NotFoundComponent,
  ],
  imports: [CommonModule, AuthRoutingModule, FormsModule, IonicModule],
  exports: [NavigationComponent],
  providers: [],
})
export class ComponentsModule {}
