import { Experience } from './../../models/experiences';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-experiences-count',
  templateUrl: './experiences-count.component.html',
  styleUrls: ['./experiences-count.component.scss']
})
export class ExperiencesCountComponent implements OnInit {

  @Input()
  experiences: Experience[];

  constructor() { }

  ngOnInit() {
  }

}
