import { Strength } from './../../models/strength.interface';
import { User } from './../../../../auth/models/user';
import { AuthService } from './../../../../auth/auth.service';
import { StrengthsService } from '../../strengths.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-strength',
  templateUrl: './add-strength.component.html',
  styleUrls: ['./add-strength.component.scss'],
})
export class AddStrengthComponent implements OnInit {
  categoryName: string;
  strengthName: string;
  strengthDescirption: string;
  strengthDescriptionUri: string;

  constructor(public strengthsService: StrengthsService) {}

  ngOnInit(): void {}

  submit(): Promise<any> {
    console.log(`Adding new Strength: ${this.strengthName}`);

    const strength: Strength = {
      category: this.categoryName,
      name: this.strengthName,
      description: this.strengthDescirption,
      descriptionUrl: this.strengthDescriptionUri,
    };
    this.clear();
    return this.strengthsService.addNewStrength(strength);
  }

  clear(): void {
    // We leave the Category name as is, as chances are the user will require this again.
    // this.categoryName = null;
    this.strengthName = null;
    this.strengthDescirption = null;
    this.strengthDescriptionUri = null;
  }
}
