import { DailyLogEntry } from './models/daily-log-entry';
import { AuthService } from './../../auth/auth.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  Action,
  AngularFirestore,
  DocumentChangeAction,
  DocumentSnapshot,
} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class DailyLogEntrysService {
  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService
  ) {}

  addNewDailyLogEntry(dailyLogEntry: DailyLogEntry): Promise<any> {

    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection('dailyLogEntrys')
        .add(Object.assign({}, dailyLogEntry))
        .then(
          (res) => {},
          (err) => reject(err)
        );
    });
  }

  updateDailyLogEntry(expierience: DailyLogEntry): Promise<void> {
    return this.firestore
      .collection('dailyLogEntrys')
      .doc(expierience.id)
      .set(expierience);
  }

  getDailyLogEntry(id: string) {
    return this.firestore.collection('dailyLogEntrys').doc(id).get();
  }

  getDailyLogEntrys(): Observable<DocumentChangeAction<unknown>[]> {
    return this.firestore
      .collection('dailyLogEntrys', (e) => e.where('ownerID', '==', `${this.authService.user.uid}`))
      .snapshotChanges();
  }

  deleteDailyLogEntry(dailyLogEntry: DailyLogEntry): Promise<void> {
    return this.firestore.collection('dailyLogEntrys').doc(dailyLogEntry.id).delete();
  }
}
