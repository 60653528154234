import { AngularFireStorage } from '@angular/fire/storage';
import { AuthRoutingModule } from './../../auth/auth-routing.module';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  videoUrl: string;

  constructor(
    private router: Router,
    private storage: AngularFireStorage,
    private elRef: ElementRef
  ) {}

  ngOnInit() {
    const ref = this.storage.ref(
      'public/StaerkeSchmiede_ParticlesAssemble0001-0200.mp4'
    );

    ref.getDownloadURL().subscribe((x) => {
      this.videoUrl = x;
    });
  }

  toSignUp() {
    this.router.navigate(['/auth/register']);
  }

  toLogin() {
    this.router.navigate(['/auth/login']);
  }
}
