import { Strength } from './models/strength.interface';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { StrengthsComponent } from './components/strengths/strengths.component';
import { StrengthDetailComponent } from './components/strength-detail/strength-detail.component';
import { StrengthsListComponent } from './components/strengths-list/strengths-list.component';
import { AddStrengthComponent } from './components/add-strength/add-strength.component';
import { FormsModule } from '@angular/forms';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgPipesModule } from 'ngx-pipes';

@NgModule({
  declarations: [
    StrengthsComponent,
    AddStrengthComponent,
    StrengthsListComponent,
    StrengthDetailComponent,
  ],
  imports: [CommonModule, FormsModule, NgPipesModule, DragDropModule],
  exports: [StrengthsComponent, StrengthsListComponent],
  providers: [],
})
export class StrengthsModule {}
