import { StrengthsService } from './../../strengths.service';
import { DocumentChangeAction } from '@angular/fire/firestore';
import { Strength } from './../../models/strength.interface';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-strength-detail',
  templateUrl: './strength-detail.component.html',
  styleUrls: ['./strength-detail.component.scss'],
})
export class StrengthDetailComponent implements OnInit {
  @Input()
  strength: Strength;
  editing = false;
  @Input()
  showCategory = true;
  @Input()
  showDetails = true;

  newString: string;

  @Output()
  edit: EventEmitter<any> = new EventEmitter();
  @Output()
  remove: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private strengthsService: StrengthsService
  ) {}
  ngOnInit(): void {
    this.route.params.forEach((params: Strength) => {
      if (params.id !== undefined) {
        this.strengthsService.getStrength(params.id).subscribe((hero) => {

          this.strength = hero.data() as Strength;
          console.log(`The ID is: '${params.id}'`);
          console.log(hero);
        });
      }
    });
    // switchMap((data: Strength) => this.strengthsService.getStrength(data.id))
    // .subscribe((data: Strength) => this.strength = data));
  }

  toggleEdit(): void {
    if (this.editing) {
      console.log(
        `Changed name from ${this.strength.name} to ${this.newString}`
      );
      this.strength.name = this.newString;
      this.edit.emit(this.strength);
    } else {
      this.newString = this.strength.name;
    }
    this.editing = !this.editing;
  }
  cancelEdit(): void {
    this.editing = false;
    this.newString = this.strength.name;
  }

  deleteEntry(event: Event): void {
    console.log('REMOVE');
    this.remove.emit(this.strength);
  }

  onNameChanged(val: string) {
    console.log(val);
    this.newString = val;
  }
}
