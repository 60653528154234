import { AuthService } from '../../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-user-portal-navigation',
  templateUrl: './user-portal-navigation.component.html',
  styleUrls: ['./user-portal-navigation.component.scss'],
})
export class UserPortalNavigationComponent implements OnInit {
  constructor(public  authService: AuthService,
              private storage: AngularFireStorage) {}

  ngOnInit(): void {}

  LogoutUser(): void {
    this.authService.SignOut();
  }
}
