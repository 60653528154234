import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-personal-strength',
  templateUrl: './add-personal-strength.component.html',
  styleUrls: ['./add-personal-strength.component.scss']
})
export class AddPersonalStrengthComponent implements OnInit {

  selfAssessment: Number;
  comment: string;

  constructor() {
    this.selfAssessment = 5;
    this.comment = '';

  }

  ngOnInit() {
  }

}
