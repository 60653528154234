import { DailyLogEntrysService } from './../../daily-log-entry.service';
import { AuthService } from './../../../../auth/auth.service';
import { User } from './../../../../auth/models/user';
import { AuthModule } from './../../../../auth/auth.module';
import { DailyLogEntry } from './../../models/daily-log-entry';
import { Experience } from './../../models/experiences';
import { Component, OnInit } from '@angular/core';
import { $ } from 'protractor';
import { materialize } from 'rxjs/operators';

@Component({
  selector: 'app-add-daily-log-entry',
  templateUrl: './add-daily-log-entry.component.html',
  styleUrls: ['./add-daily-log-entry.component.scss'],
})
export class AddDailyLogEntryComponent implements OnInit {
  logEntry: DailyLogEntry;
  experienceText: string;

  constructor(private authService: AuthService,
    private dailyLogEntryService: DailyLogEntrysService) {}

  ngOnInit() {
    this.logEntry = {
      date: Date.now(),
      creationDate: Date.now(),
      experiences: [],
      powerLevel: {
        morning: 5,
        midday: 5,
        evening: 5,
      },
      ownerID: this.authService.user.uid,
    };
  }

  submit(): void {
    this.dailyLogEntryService.addNewDailyLogEntry(this.logEntry);

  }

  onExperienceCreated(experience: Experience): void {
    this.logEntry.experiences.push(experience);
    console.log(`Added a new experience here to day ${this.logEntry.date}: ${experience.name}. Now having ${this.logEntry.experiences.length} experience-items.`);
  }

  onRemoveExperience(experience: Experience): void {
    this.logEntry.experiences = this.logEntry.experiences.filter(item => item !== experience);

    console.log(`Removed experience from day ${this.logEntry.date}: ${experience.name}. Now having ${this.logEntry.experiences.length} experience-items.`);
  }
}
