import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-authenticated-header',
  templateUrl: './authenticated-header.component.html',
  styleUrls: ['./authenticated-header.component.css'],
})
export class AuthenticatedHeaderComponent implements OnInit {
  @Input()
  pageTitle: string;

  constructor() {}

  ngOnInit() {}
}
