import { ComponentsModule } from "./../components/components.module";
import { UnauthenticatedHeaderComponent } from "./../components/unauthenticated-header/unauthenticated-header.component";
import { IonicModule } from "@ionic/angular";
import { AuthPageComponent } from "./auth-page/auth-page.component";
import { FormsModule } from "@angular/forms";
import { AuthRoutingModule } from "./auth-routing.module";
import { VerifyEmailComponent } from "./verify-email/verify-email.component";
import { RegisterComponent } from "./register/register.component";
import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "./auth.guard";

@NgModule({
  declarations: [AuthPageComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    IonicModule,
    ComponentsModule,
  ],
  exports: [AuthRoutingModule],
  providers: [AuthGuard],
})
export class AuthModule {}
