import { AuthService } from './../../auth/auth.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  AngularFirestore,
  DocumentChangeAction,
} from '@angular/fire/firestore';
import { Skill } from './models/skill.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SkillsService {
  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService
  ) {}

  addNewSkill(skill: Skill): Promise<any> {
    console.log(`skill: ${skill.name}`);

    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection('skills')
        .add(Object.assign({}, skill))
        .then(
          (res) => {},
          (err) => reject(err)
        );
    });
  }

  updateSkill(skill: Skill): Promise<void> {
    return this.firestore
      .collection('skills')
      .doc(skill.id)
      .set(skill);
  }

  getSkill(id: string) {
    return this.firestore.collection('skills').doc(id).get();
  }

  getSkills(): Observable<DocumentChangeAction<unknown>[]> {
    return this.firestore
      .collection('skills')
      .snapshotChanges();
  }

  deleteSkill(skill: Skill): Promise<void> {
    return this.firestore.collection('skills').doc(skill.id).delete();
  }
}
