import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  AngularFirestore,
  DocumentChangeAction,
} from '@angular/fire/firestore';
import { PersonalStrength } from './models/personal-strength';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PersonalStrengthsService {
  constructor(
    private firestore: AngularFirestore
  ) {}

  addNewPersonalStrength(strength: PersonalStrength): Promise<any> {
    console.log(`personalStrengths: ${strength.strengthID}`);
    console.log(strength);

    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection('personalStrengths')
        .add(Object.assign({}, strength))
        .then(
          (res) => {},
          (err) => reject(err)
        );
    });
  }

  updatePersonalStrength(strength: PersonalStrength): Promise<void> {
    return this.firestore
      .collection('personalStrengths')
      .doc(strength.id)
      .set(strength);
  }

  getPersonalStrength(id: string) {
    return this.firestore.collection('personalStrengths').doc(id).get();
  }

  getPersonalStrengths(): Observable<DocumentChangeAction<unknown>[]> {
    return this.firestore
      .collection('personalStrengths')
      .snapshotChanges();
  }

  deletePersonalStrength(strength: PersonalStrength): Promise<void> {
    return this.firestore.collection('personalStrengths').doc(strength.id).delete();
  }
}
