import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauthenticated-header',
  templateUrl: './unauthenticated-header.component.html',
  styleUrls: ['./unauthenticated-header.component.css'],
})
export class UnauthenticatedHeaderComponent implements OnInit {
  @Input()
  pageTitle: string;

  constructor() {}

  ngOnInit() {}
}
