import { IonicModule } from "@ionic/angular";
import { UserPortalNavigationModule } from "./../user-portal-navigation/user-portal-navigation.module";
import { ExperiencesService } from "./experiences.service";
import { DailyLogEntrysService } from "./daily-log-entry.service";
import { AddDailyLogEntryComponent } from "./components/add-daily-log-entry/add-daily-log-entry.component";
import { ExperiencesComponent } from "./components/experiences/experiences.component";
import { FormsModule } from "@angular/forms";
import { ExperienceDetailComponent } from "./components/experience-detail/experience-detail.component";
import { ExperiencesCountComponent } from "./components/experiences-count/experiences-count.component";
import { ExperiencesListComponent } from "./components/experiences-list/experiences-list.component";
import { AddExperienceComponent } from "./components/add-experience/add-experience.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
  declarations: [
    ExperiencesComponent,
    AddExperienceComponent,
    ExperiencesListComponent,
    ExperiencesCountComponent,
    ExperienceDetailComponent,
    AddDailyLogEntryComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    UserPortalNavigationModule,
    IonicModule,
  ],
  exports: [ExperiencesComponent],
  providers: [DailyLogEntrysService, ExperiencesService],
})
export class ExperiencesModule {}
