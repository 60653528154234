import { AuthPageComponent } from './auth-page/auth-page.component';
import { ModuleWithProviders, NgModule, Component } from "@angular/core";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { VerifyEmailComponent } from "./verify-email/verify-email.component";
import { Routes, RouterModule } from "@angular/router";

export const routes: Routes = [
  {
    path: 'auth',
    children: [
      { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
      { path: 'register', component: RegisterComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'verify-email', component: VerifyEmailComponent },
      {
        path: '',
        redirectTo: '/auth/login',
        pathMatch: 'full'
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
