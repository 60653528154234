import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Strength } from './../../models/strength.interface';
import { StrengthsService } from '../../strengths.service';
import { Component, Input, OnInit } from '@angular/core';
import { DocumentChangeAction } from '@angular/fire/firestore';

@Component({
  selector: 'app-strengths-list',
  templateUrl: './strengths-list.component.html',
  styleUrls: ['./strengths-list.component.scss'],
})
export class StrengthsListComponent implements OnInit {
  strengths: Strength[];

  @Input()
  compact = false;

  constructor(public strengthsService: StrengthsService) {
    console.log('creating StrengthsListComponent');
    this.getStrengths();
  }

  // tslint:disable-next-line: typedef
  ngOnInit() {
    console.log('Initializing StrengthsListComponent');
    console.log('Initializing StrengthsListComponent');
    this.getStrengths();
  }

  getStrengths(): void {
    this.strengthsService.getStrengths().subscribe((res) => {
      this.strengths = res.map((e) => {
        const exp = e.payload.doc.data() as Strength;
        exp.id = e.payload.doc.id;
        return exp;
      });
    });
  }

  deleteEntry = (strength) => {
    console.log('test deletion' + strength);
    this.strengthsService.deleteStrength(strength);
  };

  onRemove(v: any): void {
    console.log('test deletion' + v);
    this.deleteEntry(v);
  }

  onEdit(event: Strength): Promise<void> {
    return this.strengthsService.updateStrength(event);
  }

  drop(event: CdkDragDrop<any>) {

  }
}
