import { AddPersonalStrengthComponent } from './add-personal-strength/add-personal-strength.component';
import { PersonalStrengthsService } from './personal-strengths.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { StrengthsModule } from './../strengths/strengths.module';
import { PersonalStrengthsComponent } from './personal-strengths/personal-strengths.component';
import { FormsModule } from '@angular/forms';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [PersonalStrengthsComponent, AddPersonalStrengthComponent],
  imports: [CommonModule, FormsModule, StrengthsModule, DragDropModule],
  exports: [PersonalStrengthsComponent],
  providers: [PersonalStrengthsService],
})
export class PersonalStrengthsModule {}
