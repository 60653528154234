import { PersonalStrengthsComponent } from './personal-strengths/personal-strengths/personal-strengths.component';
import { SkillsComponent } from './skills/components/skills/skills.component';
import { ExperiencesComponent } from './experiences/components/experiences/experiences.component';
import { UserPortalComponent } from './user-portal.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { StrengthsComponent } from './strengths/components/strengths/strengths.component';
import { NgModule } from '@angular/core';

export const routes: Routes = [
  {
    path: 'user-portal',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: UserPortalComponent },
      { path: 'experiences', component: ExperiencesComponent },
      { path: 'skills', component: SkillsComponent },
      { path: 'personal-strengths', component: PersonalStrengthsComponent },
      { path: 'strengths', component: StrengthsComponent},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserPortalRoutingModule {}
