import { UserPortalRoutingModule } from './../user-portal-routing.module';
import { IonicModule } from '@ionic/angular';
import { AuthenticatedHeaderComponent } from './authenticated-header/authenticated-header.component';
import { UserPortalNavigationComponent } from "./user-portal-navigation.component";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [AuthenticatedHeaderComponent, UserPortalNavigationComponent],
  imports: [CommonModule, IonicModule, CommonModule, UserPortalRoutingModule],
  exports: [AuthenticatedHeaderComponent, UserPortalNavigationComponent, ],
  providers: [],
})
export class UserPortalNavigationModule {}
