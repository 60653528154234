import { Experience } from './../../models/experiences';
import { User } from './../../../../auth/models/user';
import { AuthService } from './../../../../auth/auth.service';
import { ExperiencesService } from '../../experiences.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-add-experience',
  templateUrl: './add-experience.component.html',
  styleUrls: ['./add-experience.component.scss'],
})
export class AddExperienceComponent implements OnInit {
  experienceText: string;

  @Output()
  experienceCreated: EventEmitter<Experience> = new EventEmitter();

  constructor(
    public experiencesService: ExperiencesService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {}

  submit(value: string): void {
    console.log(`Gotten new Experience: ${value}`);
    this.experienceText = '';

    const experience: Experience = {
      name: value,
      creationDate: Date.now(),
      ownerID: this.authService.user.uid,
    };
    this.experienceCreated.emit(experience);
  }
}
