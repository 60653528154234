import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ExperiencesService } from '../../experiences.service';
import { Component, OnInit } from '@angular/core';
import { DocumentChangeAction } from '@angular/fire/firestore';
import { Experience } from '../../models/experiences';

@Component({
  selector: 'app-experiences-list',
  templateUrl: './experiences-list.component.html',
  styleUrls: ['./experiences-list.component.scss'],
})
export class ExperiencesListComponent implements OnInit {
  experiences: Experience[];

  constructor(public experiencesService: ExperiencesService) {
    console.log('creating ExperiencesListComponent');
    this.getExperiences();
  }

  // tslint:disable-next-line: typedef
  ngOnInit() {
    console.log('Initializing ExperiencesListComponent');
    console.log('Initializing ExperiencesListComponent');
    this.getExperiences();
  }

  getExperiences(): void {
    this.experiencesService.getExperiences().subscribe((res) => {
      this.experiences = res.map((e) => {

        const exp = e.payload.doc.data() as Experience;
        exp.id = e.payload.doc.id;
        return exp;
      });
    });
  }

  deleteEntry = (experience) => {
    console.log('test deletion' + experience);
    this.experiencesService.deleteExperience(experience);
  }

  onRemove(v: any): void {
    console.log('test deletion' + v);
    this.deleteEntry(v);
  }

  onEdit(event: Experience): Promise<void> {
    return this.experiencesService.updateExperience(event);
  }

  drop(event: CdkDragDrop<any>) {
    console.log(event);
    // moveItemInArray(this.movies, event.previousIndex, event.currentIndex);
  }

}
