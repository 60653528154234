import { Skill } from './../../models/skill.interface';
import { SkillsService } from '../../skills.service';
import { Component, OnInit } from '@angular/core';
import { DocumentChangeAction } from '@angular/fire/firestore';

@Component({
  selector: 'app-skills-list',
  templateUrl: './skills-list.component.html',
  styleUrls: ['./skills-list.component.scss'],
})
export class SkillsListComponent implements OnInit {
  skills: Skill[];

  constructor(public skillsService: SkillsService) {
    console.log('creating SkillsListComponent');
    this.getSkills();
  }

  // tslint:disable-next-line: typedef
  ngOnInit() {
    console.log('Initializing SkillsListComponent');
    console.log('Initializing SkillsListComponent');
    this.getSkills();
  }

  getSkills(): void {
    this.skillsService.getSkills().subscribe((res) => {
      this.skills = res.map((e) => {

        const exp = e.payload.doc.data() as Skill;
        exp.id = e.payload.doc.id;
        return exp;
      });
    });
  }

  deleteEntry = (skill) => {
    console.log('test deletion' + skill);
    this.skillsService.deleteSkill(skill);
  };

  onRemove(v: any): void {
    console.log('test deletion' + v);
    this.deleteEntry(v);
  }

  onEdit(event: Skill): Promise<void> {
    return this.skillsService.updateSkill(event);
  }

}
