export class Skill {
  id: string | null;
  name: string;
  order: number;
  date: number;

  constructor(name: string) {
    this.name = name;
  }
}
