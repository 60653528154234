import { Skill } from './../../models/skill.interface';
import { User } from './../../../../auth/models/user';
import { AuthService } from './../../../../auth/auth.service';
import { SkillsService } from '../../skills.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-skill',
  templateUrl: './add-skill.component.html',
  styleUrls: ['./add-skill.component.scss'],
})
export class AddSkillComponent implements OnInit {
  skillText: string;

  constructor(public skillsService: SkillsService) {}

  ngOnInit(): void {}

  submit(value: string): Promise<any> {
    console.log('gotten: ' + value);

    return this.skillsService.addNewSkill(new Skill(value));
  }
}
