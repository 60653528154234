import { SkillsComponent } from './components/skills/skills.component';
import { SkillDetailComponent } from './components/skill-detail/skill-detail.component';
import { SkillsListComponent } from './components/skills-list/skills-list.component';
import { AddSkillComponent } from './components/add-skill/add-skill.component';
import { FormsModule } from '@angular/forms';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    SkillsComponent,
    AddSkillComponent,
    SkillsListComponent,
    SkillDetailComponent
  ],
  imports: [CommonModule, FormsModule],
  exports: [SkillsComponent],
  providers: [],
})
export class SkillsModule {}
