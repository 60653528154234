import { PersonalStrengthViewModel } from './../models/personal-strength-view-model';
import { StrengthsService } from './../../strengths/strengths.service';
import { PersonalStrengthsService } from './../personal-strengths.service';
import { Strength } from './../../strengths/models/strength.interface';
import { PersonalStrength } from './../models/personal-strength';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-personal-strengths',
  templateUrl: './personal-strengths.component.html',
  styleUrls: ['./personal-strengths.component.scss'],
})
export class PersonalStrengthsComponent implements OnInit {

  personalStrengths: PersonalStrength[];
  models: PersonalStrengthViewModel[];
  strengths: Strength[];
  //  = [
  //   {
  //     strengthID: 'asdcvpkov',
  //     comment: 'test',
  //     index: 5,
  //     selfAssessment: 5,
  //     id: '0',
  //   },
  //   {
  //     strengthID: 'rdbghsdfbhn',
  //     comment: 'test',
  //     index: 5,
  //     selfAssessment: 5,
  //     id: '2',
  //   },
  // ];

  constructor(private personalStrenghsService: PersonalStrengthsService,
              private strengthsService: StrengthsService) {

  }

  ngOnInit() {
    this.getStrengths();
    this.getEntries();
  }

  getStrengths(): void {
    this.strengthsService.getStrengths().subscribe((res) => {
      this.strengths = res.map((e) => {
        const exp = e.payload.doc.data() as Strength;
        exp.id = e.payload.doc.id;
        return exp;
      });
    });
  }

  getEntries(): void {

    this.personalStrenghsService.getPersonalStrengths().subscribe((res) => {
      this.models = res.map((e) => {
        const ps = e.payload.doc.data() as PersonalStrength;
        ps.id = e.payload.doc.id;

        const s = this.strengths.find(s => s.id === ps.strengthID);

        return new PersonalStrengthViewModel(ps, s);
      });
    });
  }

  deleteEntry = (personalStrength) => {
    console.log('test deletion' + personalStrength);
    this.personalStrenghsService.deletePersonalStrength(personalStrength);
  }

  onRemove(v: any): void {
    console.log('test deletion' + v);
    this.deleteEntry(v);
  }

  onEdit(event: PersonalStrength): Promise<void> {
    return this.personalStrenghsService.updatePersonalStrength(event);
  }

  drop(event: CdkDragDrop<any>): void {
    console.log('DROPPING strength:)');
    const s: Strength = event.item.data as Strength;
    if (s) {
      const personalStrength: PersonalStrength = {
        comment: `A small comment for mankind`,
        id: null,
        index: 1,
        selfAssessment: 85,
        strengthID: s.id,
      };

      console.log(`Adding new Strength: ${personalStrength}}`);
      this.personalStrenghsService.addNewPersonalStrength(personalStrength);
    }
    // moveItemInArray(this.movies, event.previousIndex, event.currentIndex);
  }
}
